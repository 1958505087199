.userinfo {
  .glass-effect {
    background: rgba(248, 248, 248, 0.05);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .btn-3d {
    display: inline-block;
    padding: 12px 35px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background: linear-gradient(145deg, #6b6b6b, #000000);
    border: none;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
  }

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-60px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .fade-in-left {
    animation: fadeInLeft 0.5s ease-out forwards;
  }

  @keyframes fadeInTop {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-top {
    animation: fadeInTop 0.5s ease-out forwards;
  }

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(90px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .fade-in-right {
    animation: fadeInRight 0.5s ease-out forwards;
  }

  @keyframes fadeInBottom {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-bottom {
    animation: fadeInBottom 0.5s ease-out forwards;
  }

  @keyframes fadeAndScale {
    0% {
      opacity: 0;
      transform: scale3d(.75,.75,1);
    }
    70% {
      opacity: 0;
      transform: scale3d(.75,.75,1);
    }
    100% {
      opacity: 1;
      transform: scale3d(1,1,1);
    }
  }
  
  .x-text-content-text-subheadline {
    animation: fadeAndScale 1s cubic-bezier(.39,-1.05,.58,1.95);
    animation-delay: -0.2s;
    transform-origin: center bottom;
  }
  
  .x-text-content-text-primary {
    animation: fadeAndScale 0.3s cubic-bezier(.39,-1.05,.58,1.95);
    transform-origin: center bottom;
  }

  .fade-out {
    opacity: 1;
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .fade-out.fade-out-active {
    opacity: 0;
    transform: translateY(20px);
  }

  @keyframes fadeOutLeft {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(-20px);
    }
  }
  
  .fade-out-left {
    animation: fadeOutLeft 0.5s ease-out forwards;
  }

  @keyframes fadeOutRight {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(20px);
    }
  }
  
  .fade-out-right {
    animation: fadeOutRight 0.5s ease-out forwards;
  }

  @keyframes fadeOutTop {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  
  .fade-out-top {
    animation: fadeOutTop 0.5s ease-out forwards;
  }

  @keyframes fadeOutBottom {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(20px);
    }
  }
  
  .fade-out-bottom {
    animation: fadeOutBottom 0.5s ease-out forwards;
  }

  // Mobile-specific styles
  @media (max-width: 767px) {
    .glass-effect {
      background: rgba(248, 248, 248, 0.1);
    }

    .btn-3d {
      padding: 10px 25px;
      font-size: 14px;
    }

    .fade-in-left,
    .fade-in-right,
    .fade-in-top,
    .fade-in-bottom {
      animation: none;
      opacity: 1;
      transform: none;
    }

    .fade-out-left,
    .fade-out-right,
    .fade-out-top,
    .fade-out-bottom {
      animation: none;
      opacity: 1;
      transform: none;
    }

    .x-text-content-text-subheadline,
    .x-text-content-text-primary {
      animation: none;
      opacity: 1;
      transform: none;
    }
  }
}