@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Light mode colors */
  --color-background: white;
  --color-border: #16E1CC;
  --color-text-selected: #00FFF2;
  --color-text-unselected: #054646;
  --color-button-bg: #055C58;
  --color-text-normal: black;
  --color-text-description: #B9B9B4;
}

.dark {
  /* Dark mode colors */
  --color-background: linear-gradient(to bottom, #001417, #000E10);
  --color-border: #16E1CC;
  --color-text-selected: #00FFF2;
  --color-text-unselected: #004642;
  --color-button-bg: #055C58;
  --color-text-normal: white;
  --color-text-description: #B9B9B9;
}

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-inter: Inter;

  /* font sizes */
  --font-size-xl: 20px;
  --font-size-mini: 15px;
  --font-size-sm: 14px;
  --font-size-xs: 12px;
  --font-size-41xl: 60px;
  --font-size-17xl: 36px;
  --font-size-29xl: 48px;

  /* Colors */
  --color-gray-100: rgba(0, 0, 0, 0.26);
  --color-blueviolet: rgba(150, 63, 249, 0);
  --color-darkslateblue: #454b79;
  --color-white: #fff;
  --color-blue: rgba(21, 45, 255, 0.03);
  --color-mediumslateblue: #a269ff;
  --color-gainsboro: #dedede;

  /* Paddings */
  --padding-18xl: 37px;
  --padding-17xl: 36px;
  --padding-3xs: 10px;

  /* Border radiuses */
  --br-3xs: 10px;
  --br-5xs: 8px;
  --bg-primary: #ffffff;
  --text-primary: #1a202c;
  --bg-secondary: #f7fafc;
  --text-secondary: #4a5568;
  --border-color: #e2e8f0;

  /* Light mode colors */
  --bg-primary: #ffffff;
  --text-primary: #1a202c;
  --bg-secondary: #f7fafc;
  --text-secondary: #4a5568;
  --border-color: #e2e8f0;
  
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .fade-in-left {
    animation: fadeInLeft 0.5s ease-out forwards;
  }

  @keyframes fadeInTop {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-top {
    animation: fadeInTop 0.5s ease-out forwards;
  }

  .dark {
    --bg-primary: #011A1E;
    --text-primary: #f7fafc;
    --bg-secondary: #2d3748;
    --text-secondary: #e2e8f0;
    --border-color: #008E87;
  }

  

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .fade-in-right {
    animation: fadeInRight 0.5s ease-out forwards;
  }

  @keyframes fadeInBottom {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-bottom {
    animation: fadeInBottom 0.5s ease-out forwards;
  }
  @keyframes fadeAndScale {
    0% {
      opacity: 0;
      transform: scale3d(.75,.75,1);
    }
    70% {
      opacity: 0;
      transform: scale3d(.75,.75,1);
    }
    100% {
      opacity: 1;
      transform: scale3d(1,1,1);
    }
  }

  code {
    font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
  }
  
  pre {
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  * code {
    white-space: pre-wrap;
    word-break: break-word;
  }
  
  .x-text-content-text-subheadline {
    animation: fadeAndScale 1s cubic-bezier(.39,-1.05,.58,1.95);
    animation-delay: -0.2s;
    transform-origin: center bottom;
  }
  
  .x-text-content-text-primary {
    animation: fadeAndScale 2s cubic-bezier(.39,-1.05,.58,1.95);
    transform-origin: center bottom;
  }
  /* Fade Out with Opacity */
  .fade-out {
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }
  
  .fade-out.fade-out-active {
    opacity: 0;

  }

  /* Fade Out with Scale */
  .fade-out {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .fade-out.fade-out-active {
    opacity: 0;
    transform: scale(0.95);
  }
  /* Fade Out with Translate */
  .fade-out {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .fade-out.fade-out-active {
    opacity: 0;
    transform: translateY(20px);
  }

  /* Fade Out with Delay */
  .fade-out {
    opacity: 1;
    transition: opacity 0.5s 0.2s ease-out;
  }
  
  .fade-out.fade-out-active {
    opacity: 0;
  }

  /* Fade Out from Left */
  @keyframes fadeOutLeft {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(-20px);
    }
  }
  
  .fade-out-left {
    animation: fadeOutLeft 0.5s ease-out forwards;
  }

  /* Fade Out from Right */
  @keyframes fadeOutRight {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(20px);
    }
  }
  
  .fade-out-right {
    animation: fadeOutRight 0.5s ease-out forwards;
  }
  /* Fade Out from Top */
  @keyframes fadeOutTop {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  
  .fade-out-top {
    animation: fadeOutTop 0.5s ease-out forwards;
  }
  /* Fade Out from Bottom */
  @keyframes fadeOutBottom {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(20px);
    }
  }
  
  .fade-out-bottom {
    animation: fadeOutBottom 0.5s ease-out forwards;
  }

}

body {
  margin: 0;
  font-family: "Varela Round", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

/* Apply these classes to your components */
.bg-primary {
  background-color: var(--bg-primary);
}

.bg-secondary {
  background-color: var(--bg-secondary);
}

.text-primary {
  color: var(--text-primary);
}

.text-secondary {
  color: var(--text-secondary);
}

.border-color {
  border-color: var(--border-color);
}

.app-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: var(--bg-primary);
}

.content {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}
.transition-width {
  transition: width 0.3s ease-in-out; /* Adjust duration as needed */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
